export enum SurveyEvent {
  Start = 'Survey - Start',
  Complete = 'Survey - Complete',
  SaveResponse = 'Survey - Save Response',
  EarlyExitAlert = 'Survey - Early Exit Alert',
  EarlyExitConfirmed = 'Survey - Early Exit Confirmed',
  EarlyExitCanceled = 'Survey - Early Exit Canceled',
  SurveyFallbackUsed = 'Survey - Fallback Used'
}

export enum NavigationEvent {
  FallbackUsed = 'Navigation - Fallback Used'
}

export enum InsuranceSurveyEvents {
  EligibleScreen = 'Insurance Survey - Web - Eligible Screen',
  IneligibleScreen = 'Insurance Survey - Web - Ineligible Screen'
}

export enum Scheduling {
  InitialAppointmentTypeViewed = 'Scheduling - Web - Initial Appointment Type Viewed',
  AppointmentBooked = 'Scheduling - Web - Appointment Booked',
  AppointmentRescheduled = 'Scheduling - Web - Appointment Rescheduled',
  AppointmentCanceled = 'Scheduling - Web - Appointment Canceled',
  AppointmentReserved = 'Scheduling - Web - Appointment Reserved',
  SlotIsAlreadyTaken = 'Scheduling - Web - Slot Is Already Taken'
}

export enum VideoCalls {
  HealthGoal = 'User - Video Call Goal',
  WeightLossQuoteViewed = 'User - Weight Loss Quote Viewed',
  InsuranceResponse = 'Standalone - Insurance Response',
  InsuranceSupportedViewed = 'Standalone - Insurance Supported Viewed',
  InsuranceNotAcceptedViewed = 'Standalone - Insurance Not Accepted Viewed',
  SubmitEmail = 'User - Submit Email Address',
  SubmitState = 'User - Submit State',
  SubmitAgenda = 'Standalone - Submit Agenda',
  HeardAboutUs = 'Standalone - Heard about us',
  EmailStepNotCompleted = 'Standalone - Email Step Not Completed'
}

export enum VideoCallsFiltering {
  DefaultSpecialtiesFilterApplied = 'Video Calls Filtering - Default Specialties Filter Applied',
  SpecialtiesFilterUpdated = 'Video Calls Filtering - Specialties Filter Updated',
  SpecialtiesFilterMenuOpened = 'Video Calls Filtering - Specialties Filter Menu Opened',
  SpecialtiesFilterMenuClosed = 'Video Calls Filtering - Specialties Filter Menu Closed',
  TimeFilterMenuOpened = 'Video Calls Filtering - Time Filter Menu Opened',
  TimeFilterMenuClosed = 'Video Calls Filtering - Time Filter Menu Closed',
  FromTimeSelected = 'Video Calls Filtering - From Time Selected',
  ToTimeSelected = 'Video Calls Filtering - To Time Selected',
  TimeFilterReset = 'Video Calls Filtering - Time Filter Reset',
  ShowingUnavailableSlots = 'Video Calls Filtering - Showing Unavailable Slots',
  UnavailableSlotConfirmed = 'Video Calls Filtering - Unavailable Slot Confirmed'
}

export enum Glp {
  Goal = 'User - Glp Goal',
  WeightLossQuote = 'User - Glp Weight Loss Quote',
  TriedLoseWeight = 'User - Tried Lose Weight',
  State = 'User - State',
  DateOfBirth = 'User - Date Of Birth',
  MedicalHistory = 'User - Medical History',
  Bmi = 'User - BMI',
  Conditions = 'User - Conditions',
  WeightLossAmount = 'User - Weight Lost Amount',
  Shipping = 'User - Shipping',
  Checkout = 'User - Checkout',
  PaymentSuccessful = 'User - Payment Successful',
  CallScheduledSuccessful = 'User - Video Call Successful',
  Descent = 'User - Descent',
  ScheduleCall = 'User - Schedule Call',
  CallRescheduleCanceled = 'User - Call Rescheduled Canceled'
}

export enum OwnSensor {
  HealthGoal = 'User - Own Sensor Goal',
  WeightLossQuoteViewed = 'User - Weight Loss Quote Viewed',
  MobileOperatingSystem = 'User - Mobile Operating System',
  SelectedSensor = 'User - Selected Sensor',
  EligibilityCheckSuccess = 'User - Eligibility Check Success',
  EligibilityCheckRejection = 'User - Eligibility Check Rejection',
  SelectedProduct = 'User - Selected Product'
}

export enum UserEvents {
  ViewSignUp = 'User - View Sign Up',
  SignUp = 'User - Sign Up',
  Identify = 'User - Identify',
  SubmitAddress = 'User - Submit Address',
  CreateSubscription = 'User - Create Subscription',
  CreditCardAdded = 'User - Credit Card Added'
}

export enum UserEventsSource {
  VideoCallFlow = 'Video Call Flow',
  DPPFlow = 'DPP Flow',
  CoursesFlow = 'Courses Flow',
  MedicalFlow = 'Medical Flow',
  Checkout = 'Checkout',
  OwnSensorFlow = 'Own Sensor Flow',
  OwnSensorCheckout = 'Own Sensor Checkout'
}

export enum DPP {
  JoinCohortViewed = 'DPP - Join Cohort - Viewed',
  JoinCohortJoined = 'DPP - Join Cohort - Joined',
  JoinCohortWait = 'DPP - Join Cohort - Wait'
}

export enum Courses {
  JoinCohortViewed = 'Courses - Join Cohort - Viewed',
  JoinCohortJoined = 'Courses - Join Cohort - Joined',
  JoinCohortWait = 'Courses - Join Cohort - Wait'
}
